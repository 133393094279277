.button {
    background-color: #62159e;
    border: none;
    color: white;
    padding: 15px 20px;
    text-align: center;
    text-decoration: none;
    display: block;
    font-size: 1.4em;
    font-family: 'Oswald', sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    margin: 8px 0;
    cursor: pointer;
    border-radius: 5px;
    width: 100%;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

.button:hover {
    background-color: #3c0d61;
    box-shadow: 0 4px 8px rgba(0,0,0,0.3);
}

.button.selected {
    background-color: #ff6b6b;
    color: #fff;
}

.buttonGroup {
    margin-bottom: 20px;
}

.buttonGrid {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-between;
}

.buttonGrid .button {
    flex: 1 1 calc(50% - 10px);
    box-sizing: border-box;
}

.actionButton {
    padding: 10px 20px;
    margin-top: 20px;
    background-color: #62159e;
    color: white;
    font-size: 18px;
    font-family: 'Oswald', sans-serif;
    text-transform: uppercase;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.actionButton:hover {
    background-color: #3c0d61;
}

.editButton, .deleteButton, .viewTeamsButton, .tableActionButton, .recordAtBatButton {
    font-size: 0.7em;
    font-family: 'Oswald', sans-serif;
    text-transform: uppercase;
    font-weight: bold;
    padding: 8px 12px;
    min-width: 70px;
    margin: 2px;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.recordAtBatButton {
    background-color: #007BFF;
    border: none;
    color: white;
    padding: 10px 15px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    width: 100%;
    min-width: 100px;
}

.editButton {
    background-color: #42b5fc;
}

.editButton:hover {
    background-color: #25638a;
}

.deleteButton {
    background-color: #fa2562;
}

.deleteButton:hover {
    background-color: #94163a;
}

.viewTeamsButton {
    background-color: #901ee8;
}

.viewTeamsButton:hover {
    background-color: #57128c;
}

.tableActionButton {
    background-color: #42b5fc;
}

.tableActionButton:hover {
    background-color: #25638a;
}

.circleBackButton {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 50px;
    height: 50px;
    background-color: #666;
    color: white;
    border-radius: 50%;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0,0,0,0.25);
}

.circleBackButton:hover {
    background-color: #444;
}

.circleBackButton svg {
    width: 70%;
    height: auto;
    fill: currentColor;
}


.addFab {
    position: fixed;
    right: 20px;
    bottom: 80px;
    width: 50px;
    height: 50px;
    font-size: 24px;
    line-height: 50px;
    color: white;
    background-color: #007BFF;
    border: none;
    border-radius: 50%;
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
    cursor: pointer;
    text-align: center;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
}

.addfab:hover {
    background-color: #0056b3;
}

.recordAtBatButtonGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5px;
    justify-items: center;
}

.recordAtBatButton:hover {
    background-color: #0056b3;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.recordAtBatButton.selected {
    background-color: #ff6b6b;
    color: #fff;
}

.recordAtBatButtonGroup {
    margin-bottom: 10px;
}

.recordAtBatActionButton {
    padding: 10px 20px;
    margin-top: 10px;
    background-color: #007BFF;
    color: white;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.recordAtBatActionButton:hover {
    background-color: #0056b3;
}

.signOutButton {
    background-color: #ff4d4d;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    text-transform: uppercase;
    transition: background-color 0.3s;
  }

  .signOutButton:hover {
    background-color: #d93b3b;
  }

  .changePasswordButton {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    text-transform: uppercase;
    transition: background-color 0.3s;
  }

  .saveButton {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    font-size: 1rem;
    font-family: 'Oswald', sans-serif;
    text-transform: uppercase;
    transition: background-color 0.3s;
    margin-top: 10px;
  }

  .saveButton:hover {
    background-color: #0056b3;
  }