svg {
    width: 100%;
    height: auto;
    max-width: 1000px;
}

svg path.hit-region {
    transition: fill 0.3s, fill-opacity 0.3s;
    cursor: pointer;
    fill-opacity: 0.5;
    fill: #007BFF;
    stroke: #0056b3;
}

svg path.hit-region:hover {
    fill: darkred;
    fill-opacity: 0.8;
}

svg path.hit-region:active {
    fill: darkred;
    fill-opacity: 0.8;
}

svg path.hit-region.selected {
    fill: red;
    fill-opacity: 0.6;
}

.large-svg {
    width: 10%;
    height: auto;
    min-width: 600px;
    margin: 0 auto;
    display: block;
}

@media only screen and (max-width: 600px) {
    .large-svg {
        min-width: 300px;
        max-width: none;
    }
}