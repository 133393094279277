table {
    margin: 20px auto;
    width: 100%;
    max-width: 70%;
    background-color: #f0f0f0;
    border-collapse: collapse;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    font-family: 'Oswald', sans-serif;
}

@media only screen and (max-width: 600px) {
    table {
        max-width: 95%;
    }
}

th, td {
    font-size: 1.1rem;
    padding: 5px;
    text-align: center;
    border-bottom: 1px solid #ddd;
    width: auto;
}

@media only screen and (max-width: 600px) {
    th, td {
        font-size: 0.8rem;
        padding: 4px;
    }
}

th {
    background-color: #62159e;
    color: white;
}

tr:nth-child(even) {
    background-color: #f2f2f2;
}

tr:nth-child(odd) {
    background-color: #ffffff;
}

tr:hover {
    background-color: #ddd;
}