.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    padding: 10px;
    box-sizing: border-box;
}

.modalForm {
    margin-top: 20px;
    margin-bottom: 20px;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 15px; /* Increased border-radius for more noticeable rounded corners */
    width: 90%;
    max-width: 500px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: relative;
}

.close {
    position: absolute;
    top: -30px;
    right: 10px;
    font-size: 5rem;
    cursor: pointer;
}
