.mainMenuContainer {
    text-align: center;
    margin-top: 30px;
    font-family: 'Oswald', sans-serif;
    text-transform: uppercase;
    padding-top: 60px;
    padding-bottom: 60px;
}

.buttonContainer {
    margin: 20px auto;
    padding: 20px;
    background-color: #f0f0f0;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
    max-width: 600px;
}

@media only screen and (max-width: 600px) {
    .buttonContainer {
        width: 75%;
        max-width: none;
    }
}

.player-select-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto 20px;
    min-width: 300px;
    max-width: fit-content;
}

.player-select-container label {
    font-size: 1.5em;
    margin-bottom: 10px;
    font-family: 'Oswald', sans-serif;
}

.player-select-container select {
    padding: 10px;
    font-size: 1em;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.formContainer {
    text-align: center;
    margin-top: 30px;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 500px;
    margin: 0 auto;
    font-family: 'Oswald', sans-serif;
}

.recentWeekStatsContainer {
    background-color: #f8f9fa;
    padding: 10px 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px auto;
    max-width: 90%;
    font-family: 'Oswald', sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.recentWeekStatsContainer h5 {
    margin: 0;
    padding: 0;
    font-size: 1.2rem;
    color: #333;
    text-align: center;
}

.finalScoreContainer {
    margin-top: 20px;
    padding: 20px;
    background-color: #f0f0f0;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 200px;
    margin: 20px auto;
    font-family: 'Oswald', sans-serif;
}

.finalScoreContainer h4 {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 10px;
}

.finalScoreContainer label {
    font-size: 1rem;
    color: #333;
    margin-bottom: 5px;
    display: block;
}

.finalScoreContainer input[type="number"] {
    padding: 10px;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-bottom: 10px;
    width: 80px;
    text-align: center;
}

.finalScoreContainer .actionButton {
    margin-top: 10px;
    width: 100%;
}

.upcomingGameContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 40px;
  }

  .upcomingGameContainer img {
    width: 150px;
    height: 150px;
  }

  .teamContainer {
    text-align: center;
    margin: 0 10px;
  }

  .gameDetails {
    text-align: center;
    margin-top: 40px;
    margin-bottom: -20px;
  }