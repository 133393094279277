.bottomNavBar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background-color: white;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-top: 1px solid #ccc;
    box-shadow: 0 -2px 5px rgba(0,0,0,0.2);
    z-index: 1000;
}

.navButton {
    background: none;
    border: none;
    color: #62159e;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    font-family: 'Oswald', sans-serif;
    text-transform: uppercase;
}

.navButton svg {
    width: 24px;
    height: 24px;
}

.navButton span {
    margin-top: 2px;
    font-size: 12px;
}

.navButton:hover {
    color: #42b5fc;
}

.topNavBar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    z-index: 1000;
}

.topNavBar .navButton {
    background: none;
    border: none;
    color: #62159e;
    display: flex;
    align-items: center;
    font-size: 24px;
}

.topNavBar .navButton svg {
    width: 32px;
    height: 32px;
}

.topNavBar .navButton:hover {
    color: #3c0d61;
}
.topNavBar .navButton:first-child {
    margin-left: 10px;
}

.topNavBar .navButton:last-child {
    margin-right: 10px;
}

.topNavBar .logoIcon {
    height: 50px;
}