@import url('./Text.css');
@import url('./Containers.css');
@import url('./Tables.css');
@import url('./Buttons.css');
@import url('./Forms.css');
@import url('./Svg.css');
@import url('./Modals.css');
@import url('./Navbar.css');

/* Recent Week Stats Styling */


/* ####################################### */
/* ############ FINAL SCORE ############## */
/* ####################################### */


/* ####################################### */
/* ########## DROPDOWN BUTTON ############ */
/* ####################################### */
.popoverAction {
    max-width: 150px;
}



.popoverBody {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 0;
}

.popoverItem {
    padding: 10px;
    border: none;
    background: none;
    font-size: 1em;
    font-family: 'Oswald', sans-serif;
    color: #62159e;
    text-align: left;
    width: 100%;
    cursor: pointer;
}

.popoverItem:hover {
    background-color: #f0f0f0;
    color: #3c0d61;
}

/* ####################################### */
/* ######## PROFILE PAGE STUFF ########### */
/* ####################################### */
.profileContainer {
    padding: 20px;
    margin-top: 60px; /* Adjust to avoid overlap with TopNavBar */
    margin-bottom: 60px; /* Adjust to avoid overlap with BottomNavBar */
    font-family: 'Oswald', sans-serif;
    text-align: center;
  }

  .profileContent {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
  }

  .profileInfo {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .profileItem {
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
  }

  .profileItem label {
    display: block;
    font-size: 1.2rem;
    color: #333;
    margin-bottom: 5px;
    text-align: center;
  }

  .profileItem div {
    text-align: center;
  }

  .profileItem select {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid #ccc;
    text-align: center;
  }

